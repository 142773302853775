import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router';
import { PrivateRoute, AnonymousRoute } from 'containers/Authentication';
import {
  selectIsAuthenticated,
  selectIsAuthenticating,
  selectIsLogoutInitiated,
  selectIsExcluded,
} from 'containers/Authentication/selectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { decodeURL } from 'utils/common';
import { isAcquisitionDomain } from 'utils/domainHelper';
import { useAuth0 } from 'auth0Provider';
import Loader from 'components/Loader';
import { isIE } from 'react-device-detect';
import {
  selectSsrReqRoute,
  selectIsPageNotFound,
} from 'containers/Landing/selectors';
import { getRedirectPathname } from 'utils/translation';

const RouteBuilder = ({
  component: ChildComponent,
  layout: Layout,
  privateRoute = false,
  anonymousOnly = false,
  isAuthenticated,
  campaignId,
  useInSelfServe = false,
  isAuthenticating = false,
  isLogoutInitiated = false,
  isExcluded = false,
  path,
  isPageNotFound = false,
  ...otherProps
}) => {
  const { loginWithRedirect, loading, logout } = useAuth0();
  const {
    isRegionalAcquistionSite,
    initiateLogin,
    redirectToPath,
    isEmailRedirect,
    isCreateAccount,
    firstName,
    lastName,
    accountNumber,
    emailId,
    userToken,
    utmQuery,
  } = decodeURL();

  const loginRedirect =
    isRegionalAcquistionSite &&
    initiateLogin &&
    !loading &&
    !isAuthenticated &&
    !isAcquisitionDomain();

  const getUtmQuery = (inPath) => {
    const hasQueryParams = inPath && inPath.includes('?');
    return utmQuery ? `${hasQueryParams ? '&' : '?'}${utmQuery}` : '';
  };

  useEffect(() => {
    if (loginRedirect && !isAuthenticating && !isLogoutInitiated) {
      const params = isCreateAccount
        ? {
            showForm: 'registration',
            firstName,
            lastName,
            accountNumber,
            emailId,
            userToken,
          }
        : {
            redirectToPath: `${window.location.pathname}${window.location.search}`,
          };
      loginWithRedirect(params);
    }

    if (isExcluded) {
      logout();
    }
  }, [loginRedirect, isAuthenticating, isLogoutInitiated]);

  if (loginRedirect || isAuthenticating) {
    return <Loader />;
  }

  if (isPageNotFound) {
    return (
      <Redirect
        to={{
          pathname: '/page-not-found',
          state: { prevPath: location.pathname },
        }}
      />
    );
  }

  const renderedChildComponent = (props) =>
    Layout ? (
      <Layout name={path}>
        <ChildComponent {...props} />
      </Layout>
    ) : (
      <ChildComponent {...props} />
    );
  const { location } = { ...otherProps };
  const routePath = `${path}${getUtmQuery(path)}`;

  if (anonymousOnly) {
    return (
      <AnonymousRoute
        {...otherProps}
        path={routePath}
        render={renderedChildComponent}
      />
    );
  }

  if (privateRoute) {
    return (
      <PrivateRoute
        {...otherProps}
        path={routePath}
        render={renderedChildComponent}
      />
    );
  }

  if (isAuthenticated && !useInSelfServe) {
    const redirectPath = isIE
      ? '/unsupported-browser'
      : isEmailRedirect
      ? `${redirectToPath}${getUtmQuery(redirectToPath)}`
      : `/account/next-delivery${utmQuery && `?${utmQuery}`}`;
    return (
      <Redirect
        to={{
          pathname: redirectPath,
          state: { prevPath: location.pathname },
        }}
      />
    );
  }

  const isDevelopment = process.env.NODE_ENV === 'development';
  const pathTrimmed = typeof path === 'string' ? path.trim() : '';
  const isRootPath = isDevelopment ? true : pathTrimmed === '/';

  const redirectPathname = getRedirectPathname(location.pathname);
  if (redirectPathname) {
    return (
      <Redirect
        to={{
          pathname: redirectPathname,
          state: { prevPath: location.pathname },
        }}
      />
    );
  }

  if (
    isRegionalAcquistionSite &&
    !isAuthenticated &&
    !isRootPath &&
    !useInSelfServe &&
    !isAcquisitionDomain()
  ) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { prevPath: location.pathname },
        }}
      />
    );
  }

  return (
    <Route {...otherProps} path={routePath} render={renderedChildComponent} />
  );
};
const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectIsAuthenticated(),
  isAuthenticating: selectIsAuthenticating(),
  isLogoutInitiated: selectIsLogoutInitiated(),
  isExcluded: selectIsExcluded(),
  ssrReqRoute: selectSsrReqRoute(),
  isPageNotFound: selectIsPageNotFound(),
});

export default connect(mapStateToProps, null)(RouteBuilder);
