/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'components/Ui';
import history from 'utils/history';
import { includesIgnoreCase } from 'utils/common';
import {
  isCanadianBrand,
  CA_EN_URL,
  CA_FR_URL,
  getLocale,
  getUrlFromLocale,
} from 'utils/translation';
import { isAcquisitionDomain, isSelfServeDomain } from 'utils/domainHelper';
import { isSsr } from 'utils/ssrHelper';

import styles from './styles.scss';

const LanguageToggle = ({ header, mobileMenu }) => {
  const isAcquisition = isAcquisitionDomain();
  const pathname = !isSsr() ? window.location.pathname : '';
  const LANGUAGES = [
    { value: CA_EN_URL, label: 'English', short: 'EN' },
    { value: CA_FR_URL, label: 'Français', short: 'FR' },
  ];
  const handleClickLanguage = (lang) => {
    // lll Language Toggle Click
    const theLang = lang.toLowerCase();
    if (isAcquisition) {
      // import { connect, useDispatch } from 'react-redux';
      // import { push } from 'connected-react-router';
      // import history from 'utils/history';
      // const dispatch = useDispatch();
      // dispatch(push(pathname.replace(lang === CA_EN_URL ? CA_FR_URL : CA_EN_URL, lang)));
      // TODO: Find a way below that does not cause a double render/route (Maybe above)
      if (!pathname.includes(theLang)) {
        history.push(
          pathname.replace(
            theLang === CA_EN_URL ? CA_FR_URL : CA_EN_URL,
            theLang,
          ),
        );
      }
    }
    // } else {
    //   history.push(`?lang=${theLang}`);
    // }
    localStorage.setItem('language', theLang);
    history.go(0);
  };

  // lll Language Toggle Display
  let value;
  if (isAcquisition) {
    value = includesIgnoreCase(CA_EN_URL, pathname)
      ? LANGUAGES[0].value
      : includesIgnoreCase(CA_FR_URL, pathname)
      ? LANGUAGES[1].value
      : CA_EN_URL;
  } else {
    const language = localStorage.getItem('language');
    value = [CA_EN_URL, CA_FR_URL].includes(language) ? language : null;
    if (!value) {
      const locale = getLocale();
      value = getUrlFromLocale(locale, CA_EN_URL);
    }
  }

  const showLanguageSelect =
    isAcquisition || isSelfServeDomain() ? isCanadianBrand() : false;
  const headerStyles = isAcquisition
    ? { position: 'absolute', right: '40px' }
    : { marginLeft: '40px' };
  const languageSelect = showLanguageSelect ? (
    <div
      className={
        header
          ? styles.headerLanguages
          : mobileMenu
          ? styles.mobileMenuLanguages
          : styles.footerLanguages
      }
      style={headerStyles}
    >
      <Button
        className={classnames(styles.button, {
          [styles.active]: value === CA_EN_URL,
        })}
        text="EN"
        onClick={() => handleClickLanguage(CA_EN_URL)}
      />
      {' | '}
      <Button
        className={classnames(styles.button, {
          [styles.active]: value === CA_FR_URL,
        })}
        text="FR"
        onClick={() => handleClickLanguage(CA_FR_URL)}
      />
    </div>
  ) : null;
  return languageSelect;
};

LanguageToggle.propTypes = {
  header: PropTypes.bool,
  mobileMenu: PropTypes.bool,
};

export default LanguageToggle;
